import api from '@/store/api';

const state = {
    items: [],
    item: {},
    load: false,
    sent: false,
    showViewer: {
        value: false,
        index: 0,
    },
};

const getters = {};
const actions = {
    getItem(context, data) {
        api.get(`/landing/${data}`, {}).then((r) => {
            context.commit('setItem', r);
        });
    },
};
const mutations = {
    setLoad(state, data) {
        state.load = data;
    },
    setItem(state, data) {
        state.item = data;
    },
    setSent(state, data) {
        state.sent = data;
    },
    setShowViewer(state, data) {
        state.showViewer = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
