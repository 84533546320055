<template>
    <div class="privacy-conatiner">
        <div class="popup__close" @click="close" >
            <svg-icon :name="'close'" :width="32" :height="32"></svg-icon>
        </div>
    <div class="privacy-text">
        <!-- eslint-disable max-len -->
        <h2>
            ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
        </h2>
            <h4>1. Общие положения</h4>
            <br><br>1.1. Настоящая политика в отношении обработки персональных данных (далее — «Политика») разработана во исполнение требований пункта 2 части 1 статьи 18.1 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (далее — «Закон о персональных данных») в целях обеспечения защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
            <br><br>1.2. Настоящая Политика действует в отношении посетителей Сайта, персональные данные которых обрабатывает Оператор.
            <br><br>1.3. Основные понятия, используемые в Политике:
            <br><br><b>Персональные данные</b> — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);
            <br><br><b>Оператор персональных данных (Оператор)</b> — ИП Земсков Алексей Васильевич (ИНН 371400551536), самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
            <br><br><b>Обработка персональных данных</b> — любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя в том числе: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение;
            <br><br><b>Автоматизированная обработка персональных данных</b> — обработка персональных данных с помощью средств вычислительной техники;
            <br><br><b>Распространение персональных данных</b> — действия, направленные на раскрытие персональных данных неопределенному кругу лиц;
            <br><br><b>Предоставление персональных данных</b> — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
            <br><br><b>Блокирование персональных данных</b> — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
            <br><br><b>Уничтожение персональных данных</b> — действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;
            <br><br><b>Обезличивание персональных данных</b> — действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;
            <br><br><b>Информационная система персональных данных</b> — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств;
            <br><br><b>Трансграничная передача персональных данных</b> — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу;
            <br><br><b>Сайт</b> — совокупность программ для электронных вычислительных машин и иной информации, содержащейся в информационной системе, доступ к которой обеспечивается посредством информационно-телекоммуникационной сети интернет и расположенной по адресу:https://zemsbaza.ru/.
            <br><br>1.4. Основные права и обязанности Оператора.
            <br><br>1.4.1. Оператор имеет право:
            <br><br><ul>
                <li>
                    самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами;
                </li>
                <li>
                    поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку персональных данных по поручению Оператора, обязано соблюдать принципы и правила обработки персональных данных, предусмотренные Законом о персональных данных;
                </li>
                <li>
                    в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных.
                </li>
            </ul>
            <br>1.4.2. Оператор обязан:
            <br><br>
            <ul>
                <li>
                    организовывать обработку персональных данных в соответствии с требованиями Закона о персональных данных;
                </li>
                <li>
                    отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;
                </li>
                <li>
                    сообщать в уполномоченный орган по защите прав субъектов персональных данных (далее — «Роскомнадзор») по запросу этого органа необходимую информацию в течение 10 рабочих дней с даты получения такого запроса.
                </li>
            </ul>
            <br>
            <br>1.5. Основные права Субъектов персональных данных. Субъект персональных данных имеет право:
            <br><br>
            <ul>
                <li>
                    получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются Субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим Субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;
                </li>
                <li>
                    требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;
                </li>
                <li>
                    обжаловать в Роскомнадзоре или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных.
                </li>
            </ul>
            <br>Субъект персональных данных может реализовать права по получению информации, касающейся обработки его персональных данных, а также права по уточнению его персональных данных, их блокированию или уничтожению путем обращения к Оператору с соответствующим запросом по электронной почте director@zemsbaza.ru. В обоих случаях запрос должен быть оформлен с соблюдением требований статьи 7.1. настоящей Политики.
            <br><br>1.6. Контроль за исполнением требований настоящей Политики осуществляется уполномоченным лицом, ответственным за организацию обработки персональных данных у Оператора.
            <br><br>1.7. Ответственность за нарушение требований законодательства Российской Федерации и локальных актов Оператора в сфере обработки и защиты персональных данных определяется в соответствии с законодательством Российской Федерации.
            <br><br>
            <br><br><h4>2. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h4>
            <br>2.1. Обработка персональных данных осуществляется Оператором в соответствии с требованиями законодательства Российской Федерации и на основе следующих принципов:
            <br><br>
            <ul>
                <li>
                    законности и справедливой основы;
                </li>
                <li>
                    ограничения обработки персональных данных достижением конкретных, заранее определённых и законных целей;
                </li>
                <li>
                    недопущения обработки персональных данных, несовместимой с целями сбора персональных данных;
                </li>
                <li>
                    недопущения объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;
                </li>
                <li>
                    обработки только тех персональных данных, которые отвечают целям их обработки;
                </li>
                <li>
                    соответствия содержания и объёма обрабатываемых персональных данных заявленным целям обработки;
                </li>
                <li>
                    недопущения обработки избыточных персональных данных по отношению к заявленным целям их обработки;
                </li>
                <li>
                    обеспечения точности, достаточности и актуальности персональных данных по отношению к целям обработки персональных данных;
                </li>
                <li>
                    уничтожения либо обезличивания персональных данных по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, при невозможности устранения Оператором допущенных нарушений персональных данных, если иное не предусмотрено федеральным законом.
                </li>
            </ul>
            <br><br><br><h4>3. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h4>
            <br>3.1. Правовым основанием обработки персональных данных является совокупность нормативных правовых актов, во исполнение которых и в соответствии с которыми Оператор осуществляет обработку персональных данных, в том числе:
            <ul>
                <li>
                    Конституция Российской Федерации;
                </li>
                <li>
                    Гражданский Кодекс Российской Федерации;
                </li>
                <li>
                    Налоговый кодекс Российской Федерации;
                </li>
                <li>
                    Федеральный закон от 6 декабря 2011 г. № 402-ФЗ «О бухгалтерском учёте»;
                </li>
                <li>
                    иные нормативные правовые акты, регулирующие отношения, связанные с деятельностью Оператора.
                </li>
            </ul>
            <br>3.2. Правовым основанием обработки персональных данных также являются:
            <br><br>
            <ul>
                <li>
                    договоры, заключаемые с Субъектом персональных данных;
                </li>
                <li>
                    согласие Субъекта персональных данных на обработку персональных данных.
                </li>
            </ul>
            <br><br><br><h4>4. ОБЪЕМ, КАТЕГОРИИ И УСЛОВИЯ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, КАТЕГОРИИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ ПРИМЕНИТЕЛЬНО К ЗАЯВЛЕННЫМ ЦЕЛЯМ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
            </h4>
            <br>4.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных. Обработке подлежат только персональные данные, которые отвечают целям их обработки.
            <br><br>4.2. На основании настоящей Политики Оператор может обрабатывать персональные данные посетителей Сайта. Оператор обрабатывает персональные данные посетителей с целью обработки входящих заявок на Сайте.
            <br><br>4.3. В предусмотренных выше целях, Оператор обрабатывает следующие категории персональных данных посетителей:
            <br>
            <br>
            <ul>
                <li>
                    фамилия, имя, отчество;
                </li>
                <li>
                    контактный телефон;
                </li>
                <li>
                    содержание поля «Комментарий»;
                </li>
                <li>
                    сведения о предпочтительном способе связи;
                </li>
                <li>
                    файлы cookies и данные, собираемые на сайте посредством агрегаторов статистики посетителей веб-сайтов.
                </li>
            </ul>
            <br>а) обработка специальных категорий персональных данных посетителей не осуществляется;
            <br><br>б) обработка биометрических персональных данных посетителей (сведений, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность) не осуществляется.
            <br><br>4.3.1. Оператор осуществляет смешанную обработку персональных данных посетителей в указанной в настоящем разделе Политики цели без передачи по внутренней сети, с передачей по сети интернет.
            <br><br>4.3.2. Перечень действий, совершаемых Оператором с персональными данными посетителей в указанной в настоящем разделе цели: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), блокирование, удаление, уничтожение.
            <br><br>4.3.3. Обработка персональных данных посетителей в указанной в настоящем разделе Политики цели осуществляется при условии получения предварительного согласия на такую обработку.
            <br><br>4.3.4. Оператор без согласия субъекта персональных данных не раскрывает третьим лицам и не распространяет персональные данные посетителей в указанной в настоящем разделе Политики цели, если иное не предусмотрено законодательством РФ.
            <br><br>4.3.5. Оператор не осуществляет трансграничную передачу персональных данных посетителей в указанной в настоящем разделе Политики цели.
            <br><br><br><br><h4>5. ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОСЕТИТЕЛЕЙ С ИСПОЛЬЗОВАНИЕМ ФАЙЛОВ COOKIE</h4>
            <br>5.1. Файлы cookie, передаваемые техническим устройствам Субъекта персональных данных, могут использоваться для предоставления Субъекту персональных данных персонализированных функций Сайта, для персональной рекламы, которая показывается Субъекту персональных данных, в статистических и исследовательских целях, а также для улучшения работы Сайта.
            <br><br>5.2. Субъект персональных данных осознает, что оборудование и программное обеспечение, используемые ими для посещения сайтов в сети интернет, могут обладать функцией запрещения операций с файлами cookie (для любых сайтов или для определенных сайтов), а также удаления ранее полученных файлов cookie.
            <br><br>5.3. Оператор вправе установить, что предоставление определенных функций Сайта возможно лишь при условии, что прием и получение файлов cookie разрешены Субъектом персональных данных.
            <br><br>5.4. Структура файла cookie, его содержание и технические параметры определяются Оператором и могут изменяться без предварительного уведомления Субъекта персональных данных.
            <br><br>5.5. Счетчики, размещенные на сайте или приложении Сайта, могут использоваться для анализа файлов cookie Субъекта персональных данных, для сбора и обработки статистической информации об использовании Сайта, а также для обеспечения работоспособности Сайта в целом или его отдельных функций в частности. Технические параметры работы счетчиков определяются Оператором и могут изменяться без предварительного уведомления Субъектов персональных данных.
            <br><br><br><h4>6. ПОРЯДОК СБОРА И ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</h4>
            <br>При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети Интернет, Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.
            <br><br>Лица, передавшие Оператору сведения о другом Субъекте персональных данных, в том числе через Сайт, не имея при этом согласия субъекта, чьи персональные данные были переданы, несут ответственность в соответствии с законодательством Российской Федерации.
            <br><br>Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является Субъект персональных данных.
            <br><br>Обрабатываемые персональные данные подлежат уничтожению в случае:
            <br><br>
            <ul>
                <li>
                    достижения срока обработки персональных данных;
                </li>
                <li>
                    достижения целей обработки персональных данных;
                </li>
                <li>
                    утраты необходимости в достижении целей обработки персональных данных;
                </li>
                <li>
                    получения отзыва согласия на обработку персональных данных;
                </li>
                <li>
                    исключения Оператора из Единого государственного реестра индивидуальных предпринимателей.
                </li>
            </ul>
            <br><br><br><h4>7. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h4>
            <br>Оператор принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, распространения и других несанкционированных действий, в том числе:
            <br><br><ul>
                <li>
                    определяет угрозы безопасности персональных данных при их обработке;
                </li>
                <li>
                    принимает локальные нормативные акты и иные документы, регулирующие отношения в сфере обработки и защиты персональных данных;
                </li>
                <li>
                    назначает лиц, ответственных за обеспечение безопасности персональных данных в структурных подразделениях и информационных системах Оператора;
                </li>
                <li>
                    создает необходимые условия для работы с персональными данными;
                </li>
                <li>
                    организует учет документов, содержащих персональные данные;
                </li>
                <li>
                    организует работу с информационными системами, в которых обрабатываются персональные данные;
                </li>
                <li>
                    хранит персональные данные в условиях, при которых обеспечивается их сохранность и исключается неправомерный доступ к ним;
                </li>
                <li>
                    организует обучение работников Оператора, осуществляющих обработку персональных данных.
                </li>
            </ul>
            <br><br><br><h4>8. АКТУАЛИЗАЦИЯ, ИСПРАВЛЕНИЕ, УДАЛЕНИЕ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ, ОТВЕТЫ НА ЗАПРОСЫ СУБЪЕКТОВ НА ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ</h4>
            <br>8.1. Подтверждение факта обработки персональных данных Оператором, правовые основания и цели обработки персональных данных, а также иные сведения, указанные в части 7 статьи 14 Закона о персональных данных, предоставляются Оператором Субъекту персональных данных или его представителю при обращении либо при получении запроса Субъекта персональных данных или его представителя в течение 10 (десяти) рабочих дней с момента поступления обращения или получения запроса. В предоставляемые сведения не включаются персональные данные, относящиеся к другим Субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.
            <br><br>8.2. Запрос должен содержать:
            <br><br>
            <ul>
                <li>
                    номер основного документа, удостоверяющего личность Субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе;
                </li>
                <li>
                    сведения, подтверждающие участие Субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором;
                </li>
                <li>
                    подпись Субъекта персональных данных или его представителя.
                </li>
            </ul>
            <br>8.3. Запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.
            <br><br>8.4. Если в обращении (запросе) Субъекта персональных данных не отражены в соответствии с требованиями Закона о персональных данных все необходимые сведения или субъект не обладает правами доступа к запрашиваемой информации, то ему направляется мотивированный отказ.
            <br><br>8.5. Право Субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с частью 8 статьи 14 Закона о персональных данных, в том числе если доступ Субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц.
            <br><br>8.6. В случае выявления неточных персональных данных при обращении Субъекта персональных данных или его представителя либо по их запросу или по запросу Роскомнадзора Оператор осуществляет блокирование персональных данных, относящихся к этому Субъекту персональных данных, с момента такого обращения или получения указанного запроса на период проверки, если блокирование персональных данных не нарушает права и законные интересы Субъекта персональных данных или третьих лиц.
            <br><br>8.7. В случае подтверждения факта неточности персональных данных Оператор на основании сведений, представленных субъектом персональных данных или его представителем либо Роскомнадзором, или иных необходимых документов уточняет персональные данные в течение семи рабочих дней со дня представления таких сведений и снимает блокирование персональных данных.
            <br><br>8.8. В случае выявления неправомерной обработки персональных данных при обращении (запросе) Субъекта персональных данных или его представителя либо Роскомнадзора Оператор осуществляет блокирование неправомерно обрабатываемых персональных данных, относящихся к этому Субъекту персональных данных, с момента такого обращения или получения запроса.
            <br><br>8.9. При достижении целей обработки персональных данных, а также в случае отзыва Субъектом персональных данных согласия на их обработку персональные данные подлежат уничтожению, если:
            <br><br>
            <ul>
                <li>
                    иное не предусмотрено договором, стороной которого является Субъект персональных данных;
                </li>
                <li>
                    оператор не вправе осуществлять обработку без согласия Субъекта персональных данных на основаниях, предусмотренных Законом о персональных данных или иными федеральными законами;
                </li>
                <li>
                    иное не предусмотрено другим соглашением между Оператором и Субъектом персональных данных.
                </li>
            </ul>
            <br><br><br><h4>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4>
            <br>9.1. Оператор вправе направлять Субъекту персональных данных сообщения рекламно-информационного характера посредством электронной почты, СМС и push-уведомлений только при условии предварительного согласия на получение рекламы согласно части 1 статьи 18 Федерального закона от 13.03.2006 г. № 38-ФЗ «О рекламе». Согласие на получение сообщений рекламного характера от Оператора посредством электронной почты, СМС и push-уведомлений предоставляется в письменной форме, либо в электронной форме при проставлении галочки в соответствующем поле на Сайте.
            <br><br>9.2. Субъект персональных данных вправе отказаться от получения сообщений рекламного характера, пройдя по соответствующей ссылке в получаемых от Оператора электронных письмах или путем обращения к Оператору с соответствующим запросом по электронной почте director@zemsbaza.ru.
            <br><br>9.3. Во исполнение требований части 2 статьи 18.1 Закона о персональных данных настоящая Политика размещается по адресу местонахождения Оператора, а также в свободном доступе в информационно-телекоммуникационной сети «Интернет» на Сайте.
    </div>
</div>

</template>

<script>
export default {
    name: 'PrivacyPolicy',
    components: {
    },
    props: {
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    computed: {
    },
    watch: {

    },
    methods: {
        close() {
            if (window.history.length <= 2) {
                this.$router.push('/');
            } else {
                this.$router.go(-1);
            }
        },
    },
};
</script>

<style lang="sass" scoped>
.privacy-conatiner
    width: 896px
    margin: 0 auto
    padding-top: 88px
    padding-bottom: 184px
    +for-mobile
        width: 296px
        padding-top: 48px
        padding-bottom: 120px

    .popup__close
        position: fixed
        top: 32px
        right: 32px
        +for-mobile
            top: 8px
            right: 8px

    .privacy-text
        font-style: normal
        font-weight: 400
        font-size: 20px
        line-height: 24px
        color: #606060
        +for-mobile
            font-size: 16px
            line-height: 18px

        h2
            font-family: 'BebasNeue', sans-serif
            font-style: normal
            font-weight: 700
            font-size: 48px
            line-height: 56px
            color: #0F0F0F
            margin-bottom: 32px
            +for-mobile
                font-size: 32px
                line-height: 32px
                letter-spacing: 0.025em

        h4
            text-transform: uppercase
            font-weight: 700
        b
            font-weight: 700
        ul
            padding-left: 1rem
            li
                list-style-type: disc
                list-style-position: outside
</style>
